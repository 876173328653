.headline.center {
  margin: 40px 0;
}

.counter-details {
  border: 0;
}

.counter-value {
  font-size: 26px;
}

.rev_slider_wrapper {
  height: auto;
  margin-bottom: 30px;
}

.rev_slider ul {
  list-style: none !important;
  position: relative;
  margin: 0 !important;
  padding: 0 !important;
  overflow-x: visible;
  overflow-y: visible;
  background-image: none;
  background-position: 0 0;
  text-indent: 0;

  li {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.full-section {
  padding: 2rem 0;
  margin-bottom: 2rem;
}

@media (max-width: 767px) {
  .rev_slider_wrapper {
    display: block !important;
    height: auto;
    margin-bottom: 10px;
  }
}